import React from "react"

import Index from "../components/layout"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
  query PageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      title
      slug
      subtitle
      content
    }
  }
`

const About = ({ data }) => {
    const page = data.strapiPage

    return (
        <Index
            title={page.title}
            description={"This page is about" + page.title}
        >
            <div className={"single-page-wrapper"}>
                <section className="blog-section blog-content">
                    <h2>{page.title}</h2>
                    <ReactMarkdown source={page.content} />
                </section>
            </div>
        </Index >
    )
}

export default About
